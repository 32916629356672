.DashboardBoxContainer {
    height: 46dvh;
    width: 49.5dvw;
    border: 2px solid #bbb;
    overflow: auto;
}

@media screen and (min-width: 2000px) {
    .DashboardBoxContainer {
        height: 45dvh;
        width: 40dvw;
        border: 2px solid #bbb;
    }
}
